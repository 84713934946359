import Vue from "vue";
import Vuex from "vuex";

import metadata from "./modules/metadata";
import options from "./modules/options";
import references from "./modules/references";

import { createSyncPlugin } from '@iebh/vuex-tera-json';

Vue.use(Vuex);

// Create TERA sync plugin with configuration
export const teraSyncPlugin = createSyncPlugin('disputatron', false, {
	debounceMs: 100
})


export default new Vuex.Store({
	modules: {
		metadata,
		options,
		references
	},
	plugins: [teraSyncPlugin],
	actions: {
		resetStore({ commit }) {
			commit("metadata/reset");
			commit("options/reset");
			commit("references/reset");
		}
	}
});
